::-webkit-scrollbar {
  width: 10px;
}

// Track
::-webkit-scrollbar-track {
  background: #171c28;
}

// Handle
::-webkit-scrollbar-thumb {
  background-color: #55198b !important;
  border-radius: 12px;
}

// Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: rgba(126, 38, 185, 0.959);
  border-radius: 12px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap');

.projectbg {
    position: absolute;
    width: 988.59px;
    height: 165.55px;
    left: 32.68px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 169px;
    opacity: 0.4;
}

.projectfr {
    position: absolute;
    width: 496.2px;
    height: 54.86px;
    left: 53px;
    padding-top: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 56px;
}

@media (max-width: 750px) {
    .projectfr {
        position: absolute;
        width: 80vw;
        height: 29px;
        font-size: 50px;
    }
    .projectbg {
        display: none;
    } // .vertical-center{
    //   margin-left: -20vw;
    // }
}

.btn_center {
    display: grid;
    place-items: center;
    margin-top: -115px;
}
section#main {
  display: flex;
  justify-content: center;
  align-items: center;
}

#main .container_proj {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

#main .container_proj .card {
  position: relative;
  width: 280px;
  height: 400px;
  margin: 20px 12px;
  overflow: hidden;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main .container_proj .card .imgBx img {
  object-fit: cover;
  width: 100%;
}

#main .container_proj .card .content {
  position: absolute;
  bottom: -200px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
  backdrop-filter: blur(15px);
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: bottom 0.5s;
  transition-delay: 0.65s;
}

#main .container_proj .card:hover .content {
  bottom: 0;
  transition-delay: 0s;
}

#main .container_proj .card .content .contentBx h3 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 2px;
  font-size: 25px;
  font-family: 'Source Serif Pro', serif;
  text-align: center;
  margin-top: 30px;
  line-height: 1em;
  transition: 0.5s;
  transition-delay: 0.6s;
  opacity: 0;
  transform: translateY(-20px);
  font-weight: bolder;
}

#main .container_proj .card:hover .content .contentBx h3 {
  opacity: 1;
  transform: translateY(0);
  font-weight: bolder;
}

#main .container_proj .card .content .contentBx h3 span {
  font-size: 12px;
  font-weight: bolder;
  text-transform: initial; // padding: 0px 5px;
}

#main .container_proj .card .content .sci {
  position: relative;
  bottom: 10px;
  display: flex;
  margin-left: 25px;
}

#main .container_proj .card .content .sci li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.2s * var(--i));
}

#main .container_proj .card:hover .content .sci li {
  transform: translateY(0);
  opacity: 1;
}

#main .container_proj .card .content .sci li a {
  color: white;
  font-size: 24px;
}
.loader_body {
  --higru: #171c28;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: var(--higru);
}

.dwf, .share {
  position: fixed;
  bottom: 4px;
  right: 10px;
  background-color: #0003;
  padding: 3px;
  border-radius: 3px;
}

.dwf .btn, .share .btn {
  color: red;
  text-decoration: none;
}

.dwf a, .share a {
  color: red;
  text-decoration: none;
}

.share {
  right: auto;
  left: 10px;
  border-radius: 50%;
  padding: 5px;
}

.share .twitter {
  width: 20px;
  fill: red;
  stroke: none;
  overflow: visible;
}

.loader {
  width: 20vw;
  max-height: 90vh;
  transform-origin: 50% 50%;
  overflow: visible;
}

.loader .ci1 {
  fill: var(--higru);
  animation: toBig 3s infinite -1.5s;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

.loader .ciw {
  transform-box: fill-box;
  transform-origin: 50% 50%;
  animation: breath 3s infinite;
}

.loader .ci2 {
  fill: var(--higru);
  animation: toBig2 3s infinite;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

.points {
  animation: rot 3s infinite;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

@keyframes rot {
  0% {
      transform: rotate(0deg);
  }
  30% {
      transform: rotate(360deg);
  }
  50% {
      transform: rotate(360deg);
  }
  80% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(0deg);
  }
}

@keyframes toBig {
  0% {
      transform: scale(1) translateX(0px);
  }
  30% {
      transform: scale(1) translateX(0px);
  }
  50% {
      transform: scale(10) translateX(-4.5px);
  }
  80% {
      transform: scale(10) translateX(-4.5px);
  }
  100% {
      transform: scale(1) translateX(0px);
  }
}

@keyframes toBig2 {
  0% {
      transform: scale(1) translateX(0px);
  }
  30% {
      transform: scale(1) translateX(0px);
  }
  50% {
      transform: scale(10) translateX(4.5px);
  }
  80% {
      transform: scale(10) translateX(4.5px);
  }
  100% {
      transform: scale(1) translateX(0px);
  }
}

@keyframes breath {
  15% {
      transform: scale(1);
  }
  40% {
      transform: scale(1.1);
  }
  65% {
      transform: scale(1);
  }
  90% {
      transform: scale(1.1);
  }
}

@media only screen and (max-width: 768px) {
  .loader {
      width: 40vw;
      max-height: 90vh;
      transform-origin: 50% 50%;
      overflow: visible;
  }
}
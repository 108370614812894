@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap');

.podcastbg {
  position: absolute;
  width: 988.59px;
  height: 165.55px;
  left: 32.68px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 100px;
  line-height: 169px;
  opacity: 0.4;
}

.podcastfr {
  position: absolute;
  width: 496.2px;
  height: 54.86px;
  left: 53px;
  padding-top: 55px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
  line-height: 56px;
}

@media (max-width: 750px) {
  .podcastfr {
      position: absolute;
      width: 80vw;
      height: 29px;
      font-size: 50px;
  }
  .podcastbg {
      display: none;
  }
}

.timeline {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  position: relative;
  padding-top: 180px;
}

.timeline-event {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  border-radius: 6px;
  align-self: center;
  width: 50vw;
}

.timeline-event:nth-child(2n + 1) {
  flex-direction: row-reverse;
}

.timeline-event:nth-child(2n + 1) .timeline-event-date {
  border-radius: 0 6px 6px 0;
}

.timeline-event:nth-child(2n + 1) .timeline-event-content {
  border-radius: 6px 0 0 6px;
}

.timeline-event:nth-child(2n + 1) .timeline-event-icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6a4ec;
  position: absolute;
  top: 0%;
  left: 50%;
  right: auto;
  z-index: -1;
  transform: translateX(-50%);
  -webkit-animation: fillTop 2s forwards 4s ease-in-out;
  animation: fillTop 2s forwards 4s ease-in-out;
}

.timeline-event:nth-child(2n + 1) .timeline-event-icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #f6a4ec;
  position: absolute;
  right: 0;
  z-index: -1;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
  -webkit-animation: fillLeft 2s forwards 4s ease-in-out;
  animation: fillLeft 2s forwards 4s ease-in-out;
}

.timeline-event-title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #9251ac;
  letter-spacing: 1.5px;
}

.timeline-event-content {
  padding: 20px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  background: #fff;
  width: calc(40vw - 84px);
  border-radius: 0 6px 6px 0;
}

.timeline-event-date {
  color: #f6a4ec;
  font-size: 1.5rem;
  font-weight: 600;
  background: #9251ac;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 20px;
  border-radius: 6px 0 0 6px;
}

.timeline-event-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9251ac;
  padding: 20px;
  align-self: center;
  margin: 0 20px;
  background: #f6a4ec;
  border-radius: 100%;
  width: 40px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  padding: 40px;
  height: 40px;
  position: relative;
}

.timeline-event-icon i {
  font-size: 32px;
}

.timeline-event-icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6a4ec;
  position: absolute;
  top: 0%;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  -webkit-animation: fillTop 2s forwards 4s ease-in-out;
  animation: fillTop 2s forwards 4s ease-in-out;
}

.timeline-event-icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #f6a4ec;
  position: absolute;
  left: 0%;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  -webkit-animation: fillLeftOdd 2s forwards 4s ease-in-out;
  animation: fillLeftOdd 2s forwards 4s ease-in-out;
}

.timeline-event-description {
  flex-basis: 60%;
}

.timeline-event-description p {
  color: black;
}

.timeline-event--type2:after {
  background: #555ac0;
}

.timeline-event--type2 .timeline-event-date {
  color: #87bbfe;
  background: #555ac0;
}

.timeline-event--type2:nth-child(2n + 1) .timeline-event-icon:before, .timeline-event--type2:nth-child(2n + 1) .timeline-event-icon:after {
  background: #87bbfe;
}

.timeline-event--type2 .timeline-event-icon {
  background: #87bbfe;
  color: #555ac0;
}

.timeline-event--type2 .timeline-event-icon:before, .timeline-event--type2 .timeline-event-icon:after {
  background: #87bbfe;
}

.timeline-event--type2 .timeline-event-title {
  color: #555ac0;
}

.timeline-event--type3:after {
  background: #24b47e;
}

.timeline-event--type3 .timeline-event-date {
  color: #aff1b6;
  background-color: #24b47e;
}

.timeline-event--type3:nth-child(2n + 1) .timeline-event-icon:before, .timeline-event--type3:nth-child(2n + 1) .timeline-event-icon:after {
  background: #aff1b6;
}

.timeline-event--type3 .timeline-event-icon {
  background: #aff1b6;
  color: #24b47e;
}

.timeline-event--type3 .timeline-event-icon:before, .timeline-event--type3 .timeline-event-icon:after {
  background: #aff1b6;
}

.timeline-event--type3 .timeline-event-title {
  color: #24b47e;
}

.timeline-event:last-child .timeline-event-icon:before {
  content: none;
}

@media (max-width: 786px) {
  .timeline-event {
      flex-direction: column;
      align-self: center;
      width: 60%;
  }
  .timeline-event-title {
      font-size: 0.95rem;
  }
  .timeline-event-content {
      width: 100%;
  }
  .timeline-event-icon {
      border-radius: 6px 6px 0 0;
      width: 100%;
      margin: 0;
      box-shadow: none;
  }
  .timeline-event-icon:before, .timeline-event-icon:after {
      display: none;
  }
  .timeline-event-date {
      border-radius: 0;
      padding: 20px;
  }
  .timeline-event:nth-child(2n + 1) {
      flex-direction: column;
      align-self: center;
  }
  .timeline-event:nth-child(2n + 1) .timeline-event-date {
      border-radius: 0;
      padding: 20px;
  }
  .timeline-event:nth-child(2n + 1) .timeline-event-icon {
      border-radius: 6px 6px 0 0;
      margin: 0;
  }
}

@-webkit-keyframes fillLeft {
  100% {
      right: 100%;
  }
}

@keyframes fillLeft {
  100% {
      right: 100%;
  }
}

@-webkit-keyframes fillTop {
  100% {
      top: 100%;
  }
}

@keyframes fillTop {
  100% {
      top: 100%;
  }
}

@-webkit-keyframes fillLeftOdd {
  100% {
      left: 100%;
  }
}

@keyframes fillLeftOdd {
  100% {
      left: 100%;
  }
}